import{makePage}from'../makePage'

import coloringPages from'./coloringPages'
import howManyWords from'./howManyWords'
import unscramble from'./unscramble'
import wordSearch from'./wordSearch'
import coding from'./coding'

const PageActivities=(props)=>makePage({content:[coloringPages,howManyWords,unscramble,wordSearch,coding],...props})

export default PageActivities
