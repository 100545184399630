import React from'react'

import Card from '@mui/material/Card'
// import CardActions from '@mui/material/CardActions';
import Grid from'@mui/material/Grid'

import{makeDialog}from'../makeDialog'

// import penelopepenny from 'static/images/penelope-coloring.jpg'
// import cuddles from 'static/images/cuddles-coloring.jpg'
// import jennie from 'static/images/jennie-coloring.jpg'
// import cover from 'static/images/cover-coloring.jpg'
// import classroom from 'static/images/classroom-coloring.jpg'
// import title from 'static/images/title-coloring.jpg'

import{PrintIm,Title}from'../components';


// const srcs=[penelopepenny,cuddles,jennie,cover,title,classroom]
const src=name=>`/images/${name}-coloring.jpg`
const srcs=['penelope','cuddles','jennie','cover','title','classroom']

export const dialogColoringPages=makeDialog({
  page:'activities',
  hash:'coloring-pages',
  buttonProps:{
    children:`coloring pages`,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:'Coloring Pages',
      // children:<Title children='Coloring Pages'/>,
    },
  },
  dialogContentProps:{
    children:<>
      <Grid container>
        {srcs.map((o,i)=><Grid
          item
          sx={{
            maxWidth:'50%'
          }}
          key={`${i}-coloring`}
          children={<Card
            children={<>
              <PrintIm src={src(o)}/>
              {/* <PrintIm src={o}/> */}
              {/* <CardActions children='print'/> */}
            </>}
            variant='outlined'
          />}
        />)}
      </Grid>
    </>,
  },
})

export default dialogColoringPages
