import * as React from'react'

import Card from '@mui/material/Card'
import Grid from'@mui/material/Grid'

import{makeDialog}from'../makeDialog'
import{PrintIm,Title}from'../components';
// import howManyWords from'static/images/howManyWords.png'


// const TypoStyle={width:'fit-content',margin:'auto'}

// const words=<Card sx={{width:'100%'}} variant='outlined'>
//   <Box sx={{padding:'30px'}}>
//     <Typo sx={TypoStyle} variant='h6'>How many words can you make from:</Typo>
//     <br/>
//     <Typo sx={TypoStyle}>PENELOPE PENNY AND THE BUBBLEGUM DRESS</Typo>
//   </Box>
// </Card>


// const unscramble=<Card sx={{width:'100%'}} variant='outlined'>
//   <Box sx={{padding:'30px'}}>
//     <Typo variant='h6' sx={TypoStyle}>Unscramble the following to manke a word found in Penelope Penny and the Bubblegum Dress</Typo>
//     <br/>
//     <Typo sx={TypoStyle}>ACIGM</Typo><br/><br/>
//     <Typo sx={TypoStyle}>SSDRE</Typo><br/><br/>
//     <Typo sx={TypoStyle}>LCDUESD</Typo><br/><br/>
//     <Typo sx={TypoStyle}>REFISND</Typo><br/><br/>
//     <Typo sx={TypoStyle}>ACIGM</Typo><br/><br/>
//     <Typo sx={TypoStyle}>ACIGM</Typo><br/><br/>
//     <Typo sx={TypoStyle}>ACIGM</Typo><br/><br/>
//     <Typo sx={TypoStyle}>ACIGM</Typo><br/><br/>
//     <Typo sx={TypoStyle}>ACIGM</Typo><br/><br/>
//   </Box>
// </Card>

const srcs=['/images/how-many-words.png']

export const dialogHowManyWords=makeDialog({
  page:'activities',
  hash:'how-many-words',
  buttonProps:{
    children:`How Many Words`,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:'How Many Words',
      // children:<Title children='How Many Words'/>,
    },
  },
  dialogContentProps:{
    children:<>
      <Grid container>
        {srcs.map((o,i)=><Grid
          item
          sx={{
            maxWidth:'100%',
            margin:'auto'
          }}
          key={`${i}-coloring`}
          children={<Card
            children={<>
              <PrintIm src={o}/>
            </>}
            variant='outlined'
          />}
        />)}
      </Grid>
    </>,

    // children:content.map((o,i)=><React.Fragment key={`${i}-words`}>{o}</React.Fragment>),
  },
})

export default dialogHowManyWords
